body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, "SF Pro", BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #00274C;
}
html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: "Courgette";
    src: url("./common/fonts/Courgette-Regular.ttf");
}
@font-face {
    font-family: "Pacifico";
    src: url("./common/fonts/Pacifico-Regular.ttf");
}
@font-face {
    font-family: "SF Pro";
    src: url("./common/fonts/SF-Pro.ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4 {
    margin: 0;
}
/* Texts */
p {
    font-size: 12pt;
    line-height: 16pt;
    margin: 0;
    margin-bottom: 12px;
}
h1 {
    font-size: 30pt;
}
h2 {
    font-size: 20pt;
    font-weight: 800;
    margin-bottom: 18px;
}
h3 {
    font-size: 16pt;
    font-weight: 800;
    margin-bottom: 8px;
}

li {
    margin-bottom: 8px;
    margin-left: -16px;
    line-height: 16pt;
}