#landing {
    border-top-style: solid;
    border-color: rgba(0, 0, 0, 0);
    /* background-image: url("./aaa-background.jpg");
    background-size: 100%; */
    position: relative;
    
}
#landing-background-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1000;
    
}
.color-overlay-blue {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #00274C;
    opacity: 0.8;
    z-index: -999;
}

/* Top Icons */
#top-buttons-container {
    position: relative;
    opacity: 0.85;
    height: 54px;
    margin-top: -12px; margin-bottom: 180px;
}
.top-logo {
    background: none;
    height: 54px; width: 54px;
    border-style: none;
    margin: 0 6px;
}
.top-icons .icon-button,
.top-icons .icon-button-invert {
    background: none;
    height: 42px; width: 42px;
    border-style: none;
    margin: 0 6px;
    padding: 8px;
}
.top-icons .icon-button-invert {
    filter: invert(1);
}
.top-icons .icon-button-invert img {
    width: 100%;
    height: 100%;
}
.top-icons .left-icons {
    position: absolute;
    left: 0;
}
.top-icons .right-icons {
    position: absolute;
    right: 0;
    top: 6px;
    height: 42px;
    margin-right: -8px;
}




/* Landing */

.landing-main-text {
    color: #FFCB05;
    /* font-family: "Pacifico", sans-serif; */
    font-size: 250%;
    margin-bottom: 12px;
}
.landing-main-description {
    font-size: 14pt;
    line-height: 19pt;
    width: 90%; max-width: 580px;
    opacity: 95%;
    margin-bottom: 12px;
}
.landing-main-button {
    font-size: 15pt;
    color: #FFCB05;
    font-weight: 600;
    text-decoration: underline;
}

/* Upcoming Events */
.upcoming-event-container-mobile,
.upcoming-event-container-mobile-small,
.upcoming-event-container-desktop {
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    margin: 0 32px 12px 0;
    
    padding: 12px 24px;
    display: inline-block;
}
.upcoming-event-container-mobile {
    width: 70%;
    margin-right: 16px;
    padding-top: 12px;
    padding-bottom: 10px;
}
.upcoming-event-container-mobile-small {
    width: 85%;
    border-radius: 8px;
    padding-bottom: 14px;
    position: relative;
}
.upcoming-event-container-mobile-small span {
    vertical-align: middle;
    margin-right: 12px;
}
.upcoming-event-more-button-mobile {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0.7);
    color: black;
    border-style: none;
    /* font-size: 12pt; */
    font-weight: 800;
    position: absolute;
    margin-top: 48px;
}
.upcoming-event-container-desktop {
    width: 25%; min-width: 250px;
    padding-top: 16px;
    min-height: 110px;
    vertical-align: top;
}
.upcoming-event-name {
    font-size: 16pt;
    margin-bottom: 2px;
}
.upcoming-event-location {
    margin-bottom: 12px;
}
.upcoming-event-time {
    margin-bottom: 2px;
}
.upcoming-event-single-text {
    margin-bottom: 12px;
    margin-top: 2px;
}
.upcoming-event-icon {
    height: 20px;
    vertical-align: middle;
    margin-right: 16px;
    padding-bottom: 1px;
}
.upcoming-event-container-mobile-small .right-icons {
    position: absolute;
    right: 8px;
    top: 12px;
    
}