#join {
    background-color: white;
    padding-bottom: 8px;
}

ul {
    margin-top: 0px;
}

a {
    color: #00274C;
    text-decoration-color: #ffcb05;
    text-decoration-thickness: 1px;
    font-weight: 600;
}
.full-image {
    width: 100%;
    max-height: 480px;
    object-fit: cover;
    object-position: 50% 25%;
    margin-top: 8px;
    margin-bottom: 16px;
}