#faq {
    background-color: #B4CEE8;
}

.faq-content-container {
    /* border-style: solid; */
    margin-top: 8px;
}
.faq-item-container {
    margin-bottom: 16px;
}
.faq-question {
    font-weight: 600;
    margin-bottom: 0;
}
.faq-answer {
    margin-bottom: 0;
}
h3 {
    margin-bottom: 12px;
}